import logoLink from '../resources/lvm.svg';
import companyLogoLink from '../resources/lvm.svg';
import footerLogoLink from '../resources/lvm_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#009B31',
    panel_highlight: '#4A4949',
    chat_background_right: '#D9EFBB',
    chat_background_left: '#DCDCDC',

    active: '#009B31',
    inactive: '#4A4949',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#D9EFBB',

    phonebook_dropdown: '#D9EFBB',
    phonebook_dropdown_svg: '#4A4949',

    primary_light: '#D9EFBB',
    primary: '#009B31',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#4A4949',
    text_on_secondary: 'white',
    focus_active: '#009B31',

    red: '#CD3111',
    green: '#009B31',

    connecting: '#4A4949',
    connected: '#009B31',
    aborted: '#CD3111',

    font_color: '#4A4949',
    link_color: '#009B31',

    font: 'Roboto, Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#D6D6D6',

    gridbox_header_bg: '#4A4949',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#009B31',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'LVM Versicherungen';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
